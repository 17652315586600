import { PureComponent } from 'react';
import Page from '../index';

export default class TH extends PureComponent<DefaultPageProps> {
  componentDidMount() {
    if (window.localStorage) {
      window.localStorage.setItem('lang', 'th');
    }
  }

  render() {
    return <Page {...this.props} prefix="th" />;
  }
}
